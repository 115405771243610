var render = function render(){
  var _vm$symbol;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('img', {
    attrs: {
      "src": `/img/icons/${((_vm$symbol = _vm.symbol) === null || _vm$symbol === void 0 ? void 0 : _vm$symbol.toLowerCase()) || ''}.svg`,
      "width": `${_vm.size || 8}px`,
      "height": `${_vm.size || 8}px`
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }